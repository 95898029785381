.container {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    width: 930px;
  }
}

@media (min-width: 1000px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media (min-width: 1519px) {
  .container {
    width: 1316px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
